@font-face {
  font-family: "futura-pt-bold";
  src: local("futura-pt-bold"), url(./assets/fonts/FuturaPTBold.otf) format("truetype");
}

@font-face {
  font-family: "futura-pt-light";
  src: local("futura-pt-light"), url(./assets/fonts/FuturaPTLight.otf) format("truetype");
}

@font-face {
  font-family: "futura-pt-medium";
  src: local("futura-pt-medium"), url(./assets/fonts/FuturaPTMedium.otf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
