@import url(https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@font-face {
  font-family: "futura-pt-bold";
  src: local("futura-pt-bold"), url(/static/media/FuturaPTBold.b22b8109.otf) format("truetype");
}

@font-face {
  font-family: "futura-pt-light";
  src: local("futura-pt-light"), url(/static/media/FuturaPTLight.725c77b4.otf) format("truetype");
}

@font-face {
  font-family: "futura-pt-medium";
  src: local("futura-pt-medium"), url(/static/media/FuturaPTMedium.7af6976c.otf) format("truetype");
}

